@import 'styles/_helpers';

.resource-entry {
    $this: &;
    margin-top: $section-spacing--medium;
    margin-bottom: $section-spacing--medium;

    &__list {
        display: grid;
        grid-gap: $gutter;
        margin-bottom: $spacing;

        @include min-width(lg) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__header {
        display: grid;
        grid-gap: $gutter;
        align-items: baseline;
        margin-bottom: $spacing;

        animation: fade-in 500ms $ease-in-out-quint--custom both;

        @include min-width(lg) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__title {
        @include font-size(title);
    }

    &__preamble {
        @include font-size(sub-title);
    }

    &__sub-title {
        @include font(base, normal);
        @include font-size(base);
        margin-bottom: 0.3em;
    }

    &__item {
        display: grid;
        grid-template-columns: 2fr 1fr;
        padding: $spacing--xsmall;
        border-radius: $border-radius--large;
        background-color: color(neutral, light);

        &:hover,
        &:focus:focus-visible {
            background-color: color(neutral);
        }

        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        &--square {
            aspect-ratio: 1/1;
            grid-template-columns: auto;

            #{$this}__image {
                order: 1;
                width: 100%;
            }

            #{$this}__content {
                order: 2;
                text-align: center;
                padding: 0.5rem 1rem 0;

                @include min-width(md) {
                    padding: 0.75rem 1rem;
                }
            }
        }
    }

    &__content {
        padding: 0.75rem 1rem;
    }

    &__meta {
        @include font-size(small);
        color: $color-muted;

        @supports (display: -webkit-box) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    &__image {
        border-radius: $border-radius;
    }

    &--large {
        #{$this}__list {
            grid-template-columns: auto;
            grid-gap: $spacing--large;

            @include min-width(md) {
                grid-gap: $gutter;
            }
        }

        #{$this}__content {
            order: 2;
            padding: 0;
        }

        #{$this}__sub-title {
            @include font(base, bold);
            font-size: inherit;
        }

        #{$this}__meta {
            @include hyphens;
            color: inherit;
            font-size: inherit;
            margin: 0 0 1.4em;

            @supports (display: -webkit-box) {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        #{$this}__item {
            @include font-size(base);
            padding: 0;
            grid-gap: $gutter;
            grid-template-columns: 1fr 2fr;
            background-color: transparent;

            @include min-width(md) {
                @include font-size(sub-title);
                grid-template-columns: 1fr 1fr;
            }

            &:hover,
            &:focus:focus-visible {
                background-color: transparent;

                #{$this}__sub-title {
                    @include underline(true);
                }
            }
        }
    }

    &--fluid {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
