@import 'styles/_helpers';

.assets {
    $this: &;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__item:not(:only-child) {
        margin-bottom: 6px;
    }
}
