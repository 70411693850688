@import 'styles/_helpers';

.sub-page {
    $this: &;

    &__header {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: $spacing--large;
    }

    &__header-content {
        grid-column: span 2;
        margin-bottom: $spacing;
    }

    &__image {
        max-height: 500px;
        border-radius: $border-radius--large;
        background-color: var(--color-light);
    }

    &__title {
        @include font-size(xl);
        max-width: 52rem;
        margin-bottom: calc(#{$spacing} * 0.8);
    }

    &__preamble {
        @include font-size(sub-title);
        max-width: 40ch;
        margin-bottom: 1em;
    }

    &__byline {
        @include font-size(small);
        display: block;
        position: absolute;
        color: $color-muted;
        margin-top: $spacing--small;
        margin-bottom: $spacing;
    }

    &__section {
        &--full {
            grid-column: 1 / -1;
        }
    }
}
