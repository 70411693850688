@import 'styles/_helpers';

.collaborators {
    @include font-size(small);
    color: $color-muted;

    &--fluid {
        font: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}
