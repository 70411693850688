@use 'sass:math';
@import 'styles/_helpers';

.content {
    $this: &;

    &__main-section {
        transition: $muted-transition;

        @include min-width(md) {
            &--active,
            &:hover,
            &:focus-within {
                #{$this}__title {
                    opacity: 1;
                }
            }
        }

        &:last-child #{$this}__section:last-child {
            padding-bottom: 0;
        }
    }

    &__section {
        // scroll-snap-align: start;

        @include font-size(sub-title);
        display: grid;
        grid-gap: $gutter;
        min-height: 12vh;
        transition: $muted-transition;
        padding-bottom: $section-spacing;

        @include min-width(md) {
            opacity: $muted-opacity;

            &--active,
            &:hover,
            &:focus-within {
                opacity: 1;
            }
        }

        @include min-width(xl) {
            grid-template-columns: 8fr 4fr;
        }

        &--large-media {
            @include min-width(xl) {
                grid-template-columns: 4fr 8fr;
            }
        }

        &--media {
            @include min-width(xl) {
                grid-template-columns: 6fr 6fr;
            }
        }

        &--tight {
            padding-bottom: 0;
        }
    }

    &__title {
        @include font-size(title);
        margin-bottom: $spacing--large;

        @include min-width(md) {
            opacity: $muted-opacity;
        }
    }

    &__sub-title {
        @include font-size(title);
        margin-bottom: 0.55em;

        @include min-width(lg) {
            margin-bottom: 0.95rem;
        }

        @include min-width(xl) {
            margin-bottom: 1rem;
        }
    }

    &__assets {
        margin-top: 1.6rem;
        margin-bottom: 1rem;

        @include min-width(lg) {
            margin-bottom: 0;
        }
    }

    &__accordions {
        margin-top: $spacing;
    }

    &__text {
        --image-full: 100%;

        p:not(:last-child) {
            margin-bottom: 1em; // * $base-line-height;
        }

        @include min-width(xl) {
            --image-full: calc(200% + #{$gutter});
        }
    }

    &__tips {
        @include min-width(md) {
            width: 50%;
        }

        @include min-width(xl) {
            width: percentage(math.div(4, 6));
            margin-left: auto;
        }

        &--left {
            @include min-width(xl) {
                margin-left: 0;
            }
        }
    }

    &__media {
        &--small {
            @include min-width(md) {
                width: 50%;
            }

            @include min-width(xl) {
                width: percentage(math.div(4, 6));
                margin-left: auto;
            }
        }
    }
}
