@import 'styles/_helpers';

.tips {
    $this: &;
    @include font-size(base);
    max-width: 100%;
    align-self: start;
    padding: $spacing;
    border-radius: $border-radius;
    background-color: color(primary, light);

    &--small {
        margin: $spacing--xsmall 0;
        padding: 14px;
        background-color: color(neutral, xlight);

        #{$this}__title {
            @include font(base, normal);
            @include font-size(small);
        }
    }

    &--simple {
        margin: $spacing 0;
        padding: 0;
        background-color: transparent;

        #{$this}__title {
            @include font(base, normal);
            margin: 0 0 0.8em;
        }
    }
}
